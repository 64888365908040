import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import { useMemo } from 'react';

import { RecoilRoot } from 'recoil';

import { Switch } from '@components/dmp';

import API from '@root/ApiClient';

import { BartDebug } from './BartDebug';
import { ResizeHandle, useResizer } from './BartResizer';
import { debugObj } from './BartShared';
import { transpileDealToBartDoc, transpileUserToBartUser } from './BartTranspiler';

export default function BartChat({ user, deal }) {
  const context = { user, deal };

  const bdoc = transpileDealToBartDoc(deal);
  console.log('bdoc=', bdoc);

  const [chatMessages, setChatMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [debugOpen, setDebugOpen] = useState(false);

  const useVectorStore = user.threads && user.threads[deal.dealID] ? user.threads[deal.dealID].useVectorStore : false;
  const threadID = user.threads && user.threads[deal.dealID] ? user.threads[deal.dealID].threadID : null;

  useHotkey({ key: 'd', onTriggered: () => setDebugOpen((lastVal) => !lastVal) });

  const [dimensions, handleResize] = useResizer({
    defaultDimensions: { width: 900, height: 400 },
    storageKey: 'bart.window.dimensions',
  });

  const [debugDimensions, debugHandleResize] = useResizer({
    defaultDimensions: { width: 400 },
    storageKey: 'bart.debugWindow.dimensions',
  });

  const clearThread = async () => {
    try {
      setIsLoading(true);
      await API.call('bart', {
        action: 'CLEAR_THREAD',
        threadID,
      });
      setChatMessages([]);
    } catch (error) {
      console.error('Failed to initialize thread:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const initThread = async () => {
    try {
      setIsLoading(true);
      if (!threadID) {
        await API.call('bart', {
          action: 'START_THREAD',
          context,
          useVectorStore,
        });
      } else {
        const { messages } = await API.call('bart', {
          action: 'FETCH_THREAD_MESSAGES',
          threadID,
        });

        const msgs = _.compact(
          _.map(messages, ({ content, role }) => {
            const contentValue = content[0].text.value;
            if (!contentValue.includes('Document:') && !contentValue.includes('User:'))
              return { role, content: contentValue };
          })
        );

        setChatMessages(msgs);
      }
    } catch (error) {
      console.error('Failed to initialize thread:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleThread = async () => {
    try {
      setIsLoading(true);
      //Step 1: Delete active thread.
      await API.call('bart', {
        action: 'DELETE_THREAD',
        threadID,
        context,
      });

      //Step 2: Restart the chat with the new option
      await API.call('bart', {
        action: 'START_THREAD',
        context,
        useVectorStore: !useVectorStore,
      });
      //Step 3: remove all messages
      setChatMessages([]);
    } catch (error) {
      console.error('Failed to toggle thread retrieval method', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    initThread();
  }, []);

  const sendUserInput = async (text) => {
    setIsLoading(true);

    setChatMessages((prev) => [
      ...prev,
      {
        role: 'user',
        content: text,
      },
    ]);

    const onStream = (data) => {
      setChatMessages((prev) => {
        const newMessages = [...prev];
        if (newMessages.length === 0 || newMessages[newMessages.length - 1].role !== 'assistant') {
          newMessages.push({ role: 'assistant', content: data });
        } else {
          newMessages[newMessages.length - 1].content += data;
        }
        return newMessages;
      });
    };

    await API.stream(
      'bartStream',
      {
        action: 'RUN_THREAD',
        threadID,
        message: text,
        context,
        useVectorStore,
      },
      onStream
    );

    setIsLoading(false);
  };

  const listRuns = async () => {
    const res = await API.call('bart', { action: 'LIST_RUNS', threadID });
    if (res.success) {
      let runs = res.runs.slice(0, 3).map((run) => {
        return { ...run, created_at: new Date(run.created_at * 1000).toLocaleString() };
      });
      debugObj('runs', runs);
    }
  };

  return (
    <div
      style={{
        zIndex: 99999,
        position: 'fixed',
        left: debugOpen ? debugDimensions.width : 0,
        bottom: 0,
        width: dimensions.width,
        height: dimensions.height,
        transition: 'left 0.3s ease',
        padding: 10,
        backgroundColor: 'skyblue',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <TitleBar />

      {/* TODO: move this into <Toolbar> component */}
      <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', width: '100%' }}>
        <div style={{ flexGrow: '1' }}></div>
        <Switch checked={useVectorStore} onChange={toggleThread} size="small" disabled={isLoading}>
          Use Vector Store (file_search)
        </Switch>
        {/* <button onClick={listRuns}>List Runs</button> */}
        {<button onClick={clearThread}>Clear Thread</button>}
      </div>

      <Conversation messages={chatMessages} />
      <UserInput onSend={sendUserInput} isLoading={isLoading} />
      <ResizeHandle onResize={handleResize} />
      <RecoilRoot>
        <BartDebug
          threadID={threadID}
          open={debugOpen}
          width={debugDimensions.width}
          handleResize={debugHandleResize}
        />
      </RecoilRoot>
    </div>
  );
}

//----------------------------------------------------------------------------------------
// BartChat Subcomponents
//----------------------------------------------------------------------------------------
function TitleBar() {
  return (
    <div style={{ fontWeight: 'bold', fontSize: 'larger', whiteSpace: 'nowrap', overflowX: 'hidden' }}>
      Bart Chat - 🚧 ⚠️FOR INTERNAL USE ONLY: DO NOT DEMO⚠️ 🚧
    </div>
  );
}

function Conversation({ messages }) {
  const textareaRef = useRef(null);

  const messagesText = useMemo(() => {
    return messages
      .map((msg) => {
        if (msg.role === 'user') return `USER: ${msg.content}\n\n`;
        if (msg.role === 'assistant') return `BART: ${msg.content}\n\n`;
        return '??? ERROR ???'; //TODO: handle this failure!
      })
      .join('');
  }, [messages]);

  useEffect(() => {
    const el = textareaRef.current;
    if (!el) return;
    el.scrollTop = el.scrollHeight;
  }, [messages]);

  return (
    <textarea
      ref={textareaRef}
      style={{
        fontSize: '11pt',
        flexGrow: '1',
        backgroundColor: 'hsl(210, 100%, 20%)',
        color: 'hsl(210, 100%, 85%)',
      }}
      value={messagesText}
      readOnly
    />
  );
}

function UserInput({ onSend, isLoading }) {
  const [text, setText] = useState('');

  const handleKeyDown = (e) => {
    if (!isLoading && e.key === 'Enter' && text.length > 0) {
      onSend(text);
      setText('');
    }
  };

  return (
    <input
      type="text"
      placeholder={isLoading ? 'Thinking...' : 'Message Bart'}
      value={text}
      onChange={(e) => setText(e.target.value)}
      onKeyDown={handleKeyDown}
      style={{ backgroundColor: isLoading ? 'hsl(0,0%,78%)' : 'white' }}
    ></input>
  );
}

//----------------------------------------------------------------------------------------
// Custom Hooks
//----------------------------------------------------------------------------------------
function useHotkey({ key, onTriggered }) {
  const handleKeyDown = (event) => {
    if (event.ctrlKey && event.key === key) {
      event.preventDefault();
      onTriggered();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
}
