import { getMarkup } from '@core/models/Content';
import Version from '@core/models/Version';

export function transpileDealToBartDoc(deal) {
  let sections = [];
  for (const [key, section] of Object.entries(deal.sections)) {
    if (section.sectiontype == 'SOURCE') {
      let currentVersion;
      //Only use the latest version if it exists
      if (section.versions) {
        const versionsArray = Object.values(section.versions);
        currentVersion = new Version(versionsArray[versionsArray.length - 1]);
        currentVersion = getMarkup(currentVersion.body);
      }

      sections.push({
        id: key,
        order: section.sourceorder ?? section.order, //The ?? operator only considers null and undefined as invalid values, so 0 would be preserved.
        type: section.sectiontype, //TODO: translate to more clear type names!
        title: section.displayname,
        content: currentVersion ?? section.content,
        parentid: section.sourceparentid || section.parentid,
        children: [],
      });
    }
  }

  //Save all section that are nested to be removed from the root array
  const sectionsToRemove = [];

  for (const [index, section] of sections.entries()) {
    if (section.parentid != null) {
      const parent = sections.find((s) => s.id === section.parentid);
      if (parent) {
        parent.children.push(section);
        sectionsToRemove.push(section.id);
      }
    } else {
      section.parentid = 'root';
    }
  }

  //Use es6 filter to remove the sections that are being removed
  sections = sections.filter((s) => !sectionsToRemove.includes(s.id));

  sections.sort((a, b) => a.order - b.order);
  sections.forEach((x) => {
    x.children.sort((a, b) => a.order - b.order);
  });

  return {
    sections,
  };
}

export function transpileUserToBartUser(user) {
  const buser = {
    id: user.id,
    email: user.info.email,
    name: user.info.fullName,
  };
  return buser;
}
